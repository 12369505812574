/*Scroll up events*/
$('.scroll-up').click(function () {
    $('html, body').animate({
        scrollTop: 0
    }, 500);
});

/*Disable touch on fancybox modal*/
$(function () {
    $('[data-fancybox]').fancybox({
        touch: false
    });
});
/**/

$(window).scroll(function () {
    if ($(window).scrollTop() > 0) {
        $('.header').addClass('fixed');
    } else {
        $('.header').removeClass('fixed');
    }

    /*Scroll up appearance on scroll*/
    if ($(window).scrollTop() > $(window).height()) {
        $('.scroll-up').fadeIn();
    } else {
        $('.scroll-up').fadeOut();
    }
    /**/
});

/*extendable block*/
$('[data-extendable]').each(function () {
    var extendableBlock = $(this).siblings('.extendable'),
        title = $(this).text(),
        alternativeTitle = $(this).data('alternative-title');
    extendableBlock.css('max-height', $(this).data('extendable'));

    $(this).click(function () {
        extendableBlock.toggleClass('opened');
        if (extendableBlock.hasClass('opened')) {
            $(this).text(alternativeTitle).attr('title', alternativeTitle);
        } else {
            $(this).text(title).attr('title', title);
        }
    });

    if (extendableBlock.find('.tags').outerHeight()<$(this).data('extendable') + 1) {
        $(this).hide();
    }
});
/**/

/*Menu-btn*/
$('.menu-btn').click(function () {
    $(this).toggleClass('opened');
    $('.mobile-menu').toggleClass('opened');
    $('.header').toggleClass('menu-opened');
});
/**/

function typeIt() {
    if (count < typeCount) {
        count = count + 1;
    } else {
        count = 1;
    }
    new TypeIt("#type", {
        speed: 50,
        afterComplete: function () {
            typeIt();
        }
    }).pause(1200).type($('#type-data').children().eq(count - 1).html()).pause(2000).delete().go();
};

$('[data-modal]').click(function () {
   var target = $(this).data('modal');
   $('.own-modal.opened').removeClass('opened');
   $('#' + target).addClass('opened');
});

$(function () {
    $('.own-modal .icon-close').click(function () {
        $(this).closest('.own-modal').removeClass('opened');
    });

    $('.mobile-link > a').click(function () {
        $(this).siblings('ul').slideToggle(300);
        $(this).parent().toggleClass('active');
    });
});

function parallax() {
    $('.parallax').each(function () {
        if ($(window).scrollTop() + $(window).height() > $(this).offset().top && $(window).scrollTop() < $(this).offset().top + $(this).outerHeight()) {
            var k = (($(window).height()/2 + $(this).outerHeight()/2) - ($(window).scrollTop() + $(window).height() - $(this).offset().top))/($(window).height()/2 + $(this).outerHeight()/2);
            $(this).css('transform', 'translate3d(0,' +  (-300*k) + 'px,0)');
        }
    });
}

if ($(window).width() > 1024) {
    parallax();
}

$(window).scroll(function () {
    if ($(window).width() > 1024) {
        parallax();
    }
});

/*Tabs*/
$('.tabs .tabs-titles>*').click(function (event) {
    var parent = $(this).closest('.tabs'),
        desc = parent.find('.tabs-contents>div').eq($(this).index());
    parent.find('.tabs-contents>div.active').removeClass('active');
    desc.addClass('active');
    $(this).addClass('active').siblings('.active').removeClass('active');

    if ($(window).width() < 1025) {
        $(this).closest('.mobile-dropdown').removeClass('opened');
    }
});
/**/

YaMapsShown = false;

$(document).ready(function () {
    if ($(window).width() > 767) {
        setTimeout(function () {
            showYaMaps();
            YaMapsShown = true;
        }, 1000);
    }
});

function showYaMaps(){
    var script   = document.createElement("script");
    script.type  = "text/javascript";
    script.src   = "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Af3bb41824bced3d37e799466478aa41efdcda4b2be732c6cfd06c9c986eca221&amp;width=100%25&amp;height=250&amp;lang=ru_RU&amp;scroll=true";
    if ($('#map').length) {
        document.getElementById("map").appendChild(script);
    }
    if ($('#inlineMap').length) {
        document.getElementById("inlineMap").appendChild(script);
    }
}